namespace gotoAndPlay {

    export class SuggestionField {

        static initSelector: string = '.suggestion-field';

        element: JQuery;
        input: JQuery;
        clearButton: JQuery;
        dropdown: JQuery;
        suggestions: JQuery;
        empty: JQuery;

        searchXhr: JQueryXHR;
        searchTimeout: number;

        constructor(target: HTMLElement) {
            this.element = $(target);
            this.input = this.element.find('.suggestion-field__input');
            this.clearButton = this.element.find('.suggestion-field__clear');
            this.dropdown = this.element.find('.suggestion-field__dropdown');
            this.suggestions = this.dropdown.find('.suggestion-field__suggestions');
            this.empty = this.dropdown.find('.suggestion-field__empty');

            this.init();
        }

        init(): void {
            this.input.on('input', this.onSearch.bind(this));
            this.clearButton.on('click', (event: JQuery.TriggeredEvent) => {
                event.preventDefault();

                this.clear();
            });
            $(document).on('click', this.onOutsideClick.bind(this));
        }

        onSearch(): void {
            if (this.searchTimeout) {
                window.clearTimeout(this.searchTimeout);
            }

            this.searchTimeout = window.setTimeout(this.onInput.bind(this), 200);
        }

        onInput(): void {
            // Remove is-empty class/hide empty text
            this.element.removeClass('is-empty');

            // Get input value
            const value: string = String(this.input.val());

            // Trigger ajax request
            if (value) {
                // Make clear button visible
                this.element.addClass('is-dirty');

                this.search(value);
            } else {
                // Hide clear button
                this.element.removeClass('is-dirty');

                // Close suggestions
                this.close();
            }
        }

        search(value: string): void {
            if (this.searchXhr) {
                this.searchXhr.abort();
            }

            this.searchXhr = Ajax.post({
                action: 'autosuggest',
                data: value,
            });

            this.element.removeClass('is-empty');
            this.searchXhr.then((results: string): void => {
                if (results) {
                    this.suggestions.html(results);
                } else {
                    this.element.addClass('is-empty');
                }

                this.open();
            });
            this.searchXhr.catch(() => false);
        }

        open(): void {
            this.element.addClass('is-open');
        }

        close(): void {
            this.element.removeClass('is-open');
        }

        clear(): void {
            this.input.val('');
            this.close();
        }

        onOutsideClick(event: JQuery.TriggeredEvent): void {
            if (!$(event.target).parents('.suggestion-field__dropdown').length) {
                this.close();
            }
        }
    }

    $(document).on('enhance.suggestion-field', function (event) {
        $(event.target).find(SuggestionField.initSelector).addBack(SuggestionField.initSelector).each((index: number, element: Document) => {
            if (element instanceof HTMLElement) {
                let field = $(element);

                if (!field.data('suggestionFieldClass')) {
                    field.data('suggestionFieldClass', new SuggestionField(element));
                }
            }
        });
    });

    $(() => {
        $(document).trigger('enhance.suggestion-field');
    });
}
